/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'

import React, { useMemo } from "react"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"

import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets"
import { clusterApiUrl } from "@solana/web3.js"

import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"

// Wraps every page in a component
// eslint-disable-next-line no-undef
export const wrapPageElement = ({ element, props }) => {
  const network = WalletAdapterNetwork.Mainnet
  const endpoint = 'https://solana-api.projectserum.com'
  const wallets = [
    new PhantomWalletAdapter(),
  ]

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{element}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
